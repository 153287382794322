<template>
<footer class="footer">
	<div class="footer__content container">
        <div class="column is-6">
            <span class="has-text-white">email: <a href="mailto:wojciech@szczepaniak.ch" target="_top"></a>wojciech@szczepaniak.ch</span>
        </div>
        <div class="footer__logo-box column is-6 is-paddingless is-marginless">
            <img class="footer__logo" src="https://szczepaniak.ch/cms/app/uploads/2020/02/WS_Logo_02.svg" alt="SW logo">
        </div>
	</div>
</footer>
</template>

<script>
export default {};
</script>

<style scoped lang="sass">
@import "@/styles/framework/variables.sass"


.footer
    background-color: $dark-blue
    display: flex
    padding: $big $big $big 140px
    justify-content: center
    +mobile
        padding: 30px $medium $medium
        text-align: center
    &,
    &__content
        display: flex
        box-sizing: border-box
        justify-content: space-between
        width: 100%
    &__content
        flex-direction: row
        justify-content: space-between
        +mobile
            flex-direction: column-reverse
    &__logo
        width: 250px


.footer
	&__box
		flex-direction: column
		align-items: center
		justify-content: center
		width: 100%

.footer
    &__logo-box
        display: flex
        justify-content: flex-end
        +mobile
            justify-content: center
</style>
