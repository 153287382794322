<template>
<section class="main-slider"
         :id="`section-${idNumber}`"
         :class="[data.custom_class]"
         :data-aos="data.animation"
         data-aos-duration="1500">
	<!--<div class="main-slider__logo"></div>-->
	<div class="main-slider__image">
		<img src="https://szczepaniak.ch/cms/app/uploads/2021/01/WS_BUSINESS2-1-1.png">
	</div>
	<!--<div class="main-slider__video">
		<video autoplay
		       loop>
			<source src="https://szczepaniak.ch/cms/app/uploads/2019/07/media.io_Pexels-Videos-2327215-1-1.mp4"
			        type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"' />
		</video>
	</div>-->
	<transition-group tag="div"
	                  :name="transitionName"
	                  class="slides-group">
		<div v-if="show"
		     :key="current"
		     class="main-slider__slide"
		     :class="data.singlecell[current].title">
			<div class="container">
				<div class="columns main-slider__columns">
					<div class="column is-5 is-hidden-tablet-only"></div>
					<div class="column is-7-desktop is-12-tablet-only main-slider__column">
						<div class="main-slider__content">
							<p class="is-size-4">{{ data.singlecell[current].content }}</p>
							<span><a :href="data.singlecell[current].link_url"
								   @click.prevent="navigateTo('/' + data.singlecell[current].link_url)">{{ data.singlecell[current].link_title }}</a></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition-group>
	<div class="main-slider__buttons">
		<!--<a @click.prevent="slide(1)"><img src="https://szczepaniak.ch/cms/app/uploads/2019/07/ARROW.svg"></a>-->
		<a @click.prevent="slide(1)"><img src="https://szczepaniak.ch/cms/app/uploads/2021/01/rightarrow.svg"></a>
	</div>
</section>
</template>

<!-- data.singlecell -->

<script>
export default {
	data() {
		return {
			current: 0,
			show: false,
			direction: 1,
			transitionName: "fade"
		}
	},
	methods: {
		slide(dir) {
			this.direction = dir;
			dir === 1 ? (this.transitionName = "slide-next") : (this.transitionName = "slide-prev")
			var len = this.data.singlecell.length
			this.current = (this.current + dir % len + len) % len
		},
		navigateTo(url) {
			const publicPath = "/" + this.$store.getters.getPublicPath;
			const locale = this.$store.getters.getLocale;
			if (url != "#") {
				this.$router.push(url);
				this.burger = false;
			}
		},
	},
	created() {
		setInterval(() => {
				this.transitionName = "slide-next"
				var len = this.data.singlecell.length
				this.current = (this.current + 1 % len + len) % len
			}, 10000),
			this.show = true
	}
	// mounted() {
	//     // setInterval(() => {
	//     //     this.transitionName = "slide-next"
	//     //     var len = this.data.singlecell.length
	//     //     this.current = (this.current + 1 % len + len) % len
	//     //     console.log(this.current)
	//     // }, 3000),
	//     this.show = true
	// }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.fade-enter-active {
    transition: opacity .2s;
}
.fade-enter {
    opacity: 0;
}

.slide-next-enter-active,
.slide-next-leave-active {
    transition: transform 1s ease-in-out, opacity .3s ease-in-out;
}
.slide-next-enter {
    transform: translate(30%);
    opacity: 0;
}
.slide-next-leave-to {
    transform: translate(-100%);
    opacity: 0;
}

/* GO TO PREVIOUS SLIDE */
.slide-prev-enter-active,
.slide-prev-leave-active {
    transition: transform .5s ease-in-out, opacity 0.2s ease-in-out;
}
.slide-prev-enter {
    transform: translate(-100%);
    opacity: 1;
}
.slide-prev-leave-to {
    transform: translate(100%);
    opacity: 0;
}

.main-slider {
    width: 100%;
    height: 600px;
    background-color: $beige;
    position: relative;
    overflow: hidden;
    // height: calc(100vh - 50px);
    &__slide {
        width: 100%;
        height: 600px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 900;
    }
    &__buttons {
        position: absolute;
		right: 30px;
        top: 50%;
		transform: translateY(-50%);
        z-index: 900;
		@include touch {
			right: 0;
        }
        a {
            display: inline-block;
            padding: 13px 10px 10px;
            margin-right: 1px;
            img {
                width: 22px;
            }
            &:nth-child(2) {
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &__columns {
        height: 600px;
    }
    &__content {
        color: $dark-blue !important;
        font-weight: 500;
        z-index: 800;
		margin-right: 100px;
        .is-size-4 {
            @include mobile {
                font-size: 1.25rem !important;
            }
        }
        span {
            margin-top: 20px;
            font-weight: 300;
            display: block;
            position: relative;
            margin-left: 30px;
            &::before {
                content: '';
                width: 20px;
                height: 1px;
                background-color: $dark-blue;
                display: block;
                position: absolute;
                left: -30px;
                top: 11px;
            }
        }
        @include touch {
            margin-right: 80px;
        }
    }
    &__column {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        @include hugemax {
            margin: 0 20px;
        }
        @include touch {
            width: 100% !important;
            max-width: 800px;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
		bottom: 0;
        z-index: 800;
        //background-color: #efefef;
		img{
			left: 10%;
			bottom: 0;
			position: absolute;
			max-width: 530px;
			width: 90%;
			@include desktop-only{
				left: 2%;
			}
		}
        @include touch {
            display: none;
        }
    }
    &__video {
        position: absolute;
        top: 0;
        right: 0;
        @include touch {
            display: none;
        }
    }
    &__logo {
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-image: url("https://szczepaniak.ch/cms/app/uploads/2019/07/W_OUTLINE.svg");
        background-size: 35%;
        background-repeat: no-repeat;
        background-position: 20% 50%;
    }

    // Modification for 2 version
    &--version-2 {
        .main-slider__content {
            color: $dark-blue;
            font-weight: 500;
            p,
            span {
                color: $dark-blue;
                opacity: 0.88;
            }
        }
        .main-slider__image {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
            @include touch {
                display: none;
            }
        }
    }
}
</style>
