<template>
    <section class="media-home" :data-aos="data.animation" data-aos-duration="1500">
        <div class="contanier">
            <div class="columns">
                <div class="media-home__img-box column is-4">
                    <img :src="data.logo.url" :alt="data.logo.alt">
                </div>
                <div class="column is-8">
                    <div class="media-home__text-box">
                        <span class="media-home__page-name">{{ data.page_name }}</span>
                        <p class="media-home__quote">{{ data.quote }}</p>
                        <router-link :to="data.link">{{ data.link_text }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";

.media-home {
    box-sizing: border-box;
    padding: 80px 40px;
    background-color: $dark-blue;
    color: $white;
    @include mobile {
        padding: 50px 20px;
    }
    &__img-box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        img {
            max-width: 300px;
            width: 100%;
        }
    }
    &__text-box {
        display: flex;
        flex-direction: column;
        a {
            position: relative;
            color: white;
            right: -30px;
            &::before {
                content: '';
                width: 20px;
                height: 1px;
                background-color: $white;
                display: block;
                position: absolute;
                left: -30px;
                top: 11px;
            }
        }
    }
    &__page-name {
        color: $light-blue;
        text-transform: uppercase;
    }
    &__quote {
        margin: 20px 0;
        line-height: 1.5;
        font-size: 1.5rem;
        font-weight: 500;
        @include touch {
            font-size: 1.25rem;
        }
    }
}
</style>