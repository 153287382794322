import Vue from "vue";
import App from "./App.vue";
import VueScrollTo from "vue-scrollto";
import VueAnalytics from "vue-analytics";
import Meta from "vue-meta";
import VueI18n from "vue-i18n";
import Router from "vue-router";
import Page from "./views/Page.vue";
import VideoPosts from "./components/flex/VideoPosts.vue";
import PageNotFound from "./views/PageNotFound.vue";
import VueCookies from "vue-cookies";
import VueLazyload from "vue-lazyload";
import VueCarousel from "vue-carousel";
import store from "./store";
import "aos/dist/aos.css";

// import styles
require("./styles/main.sass");

store
  .dispatch("fetchContent")
  .then(() => {
    // use addons
    Vue.config.productionTip = false;
    Vue.use(VueScrollTo, { offset: -80 });
    Vue.use(Meta);
    Vue.use(VueI18n);
    Vue.use(Router);
    Vue.use(VueCookies);
    Vue.use(VueLazyload);
    Vue.use(VueCarousel);
    const publicPath = "/" + store.getters.getPublicPath;
    const locale = store.getters.getLocale;
    const defaultLocale = store.getters.getOptions.default_language;
    // initialize router
    const router = new Router({
      mode: "history",
      base: locale != defaultLocale ? publicPath + locale + "/" : publicPath,
      routes: [
        { path: "/post/:id", component: VideoPosts },
        { path: "/" + defaultLocale, redirect: { name: "home" } },
        { path: "/" + defaultLocale + "/:id", redirect: "/:id" },
        {
          path: "/" + defaultLocale + "/:parent/:id",
          redirect: "/:parent/:id"
        },
        { path: "/", name: "home", component: Page },
        { path: "/:id", component: Page },
        { path: "/:parent/:id", component: Page },
        { path: "/404", name: "error404", component: PageNotFound },
        { path: "*", redirect: { name: "error404" } }
      ],
      scrollBehavior(to) {
        if (to.hash) {
          return new Promise(resolve => {
            setTimeout(() => {
              VueScrollTo.scrollTo(to.hash, 1000);
              resolve({ selector: to.hash });
            }, 1000);
          });
        } else {
          return new Promise(resolve => {
            setTimeout(() => {
              resolve({ x: 0, y: 0 });
            }, 600);
          });
        }
      }
    });
    // config langs
    const i18n = new VueI18n({
      locale: locale.trim().length && locale != "/" ? locale : defaultLocale,
      fallbackLocale: defaultLocale
    });
    // Google analytics
    if (process.env.VUE_APP_GA) {
      Vue.use(VueAnalytics, {
        id: process.env.VUE_APP_GA,
        debug: {
          sendHitTask: process.env.NODE_ENV === "production"
        },
        router
      });
    }
    // create Vue instance
    const vm = new Vue({
      router,
      i18n,
      store,
      render: h => h(App)
    });
    vm.$mount("#app");
    //  emit "render-event" after fetching data from api endpoint
    document.dispatchEvent(new Event("render-event"));
  })
  .catch(error => {
    console.error(error);
  });
