<template>
	<section class="arrows section"
        :class="[data.custom_class]"
    >
		<div class="container">
			<div class="arrows__content-box">
				<div class="arrows__arrow-box" v-if="data.back">
					<a :href="data.back" class="arrows__btn arrows__btn--back" @click.prevent="navigateTo('/'+data.back)">
						<svg class="arrows__arrow arrows__arrow--back" ersion="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
							<g>
								<polygon class="st0" points="17.7,46.6 0.9,30 17.7,13.4 19.8,15.5 6.7,28.5 57,28.5 57,31.5 6.7,31.5 19.8,44.5 	"/>
							</g>
						</svg>
						<span class="arrows__text" v-if="data.back != '/' ">{{ data.back }}</span>
						<span class="arrows__text" v-if="data.back == '/' ">Home</span>
					</a>
				</div>
				<div class="arrows__arrow-box" v-if="data.next">
					<a :href="data.next" class="arrows__btn arrows__btn--next" @click.prevent="navigateTo('/'+data.next)">
						<span class="arrows__text" v-if="data.next">{{ data.next }}</span>
						<svg class="arrows__arrow arrows__arrow--next" ersion="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
							<g>
								<polygon class="st0" points="17.7,46.6 0.9,30 17.7,13.4 19.8,15.5 6.7,28.5 57,28.5 57,31.5 6.7,31.5 19.8,44.5 	"/>
							</g>
						</svg>
					</a>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {
		navigateTo(url){
			const publicPath = '/' + this.$store.getters.getPublicPath
			const locale = this.$store.getters.getLocale
			if(url != '#'){
				if (url == '//') {
					console.log(url)
					this.$router.push('/')
				}else{
					this.$router.push(url)
					this.burger = false
				}
			}
       }
	}
}
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"

.section
	+touch
		padding: 0 .5rem .5rem

.arrows
    transform: translateY(-6px)
    margin-top: 6px
    +touch
        margin-bottom: 2rem
        padding: 0 .5rem .5rem
    +desktop-only
        transform: translateY(0px)
        margin-top: 1.5rem
    +touch
        transform: translateY(0px)
        margin-top: 1.5rem
    &--extra-margin-top
        margin-top: 18px
    &__content-box
        display: flex
        justify-content: space-between
    &__arrow-box
        display: flex
        justify-content: space-between
        align-items: center
        background-color: $light-blue
        min-width: 175px
        +mobile
            min-width: 0
    &__btn
        background-color: $light-blue
        width: 100%
        display: flex
        height: 50px
        align-items: center
        justify-content: space-between
        box-sizing: border-box
        padding: 25px
        +mobile
            padding: 15px
        &--back
            background-color: $light-grey
        &--next
            background-color: $light-blue
    &__text
        text-transform: uppercase
        color: $white
    &__arrow
        width: 30px
        &--next
            transform: rotate(180deg)
            +mobile
                margin-left: 5px
        &--back
            +mobile
            margin-right: 5px
        & .st0
            fill: $white
    &--transform-top
        transform: translateY(-57px)
        +desktop-only
            transform: translateY(-15px)
        +touch
            transform: translateY(0)

</style>
