<template>
    <section
        :data-aos="data.animation"
        data-aos-duration="1500"
        class="section quote"
        :class="[data.custom_class]"
        :style="{backgroundImage: 'url(' + data.image + ')' }"
    >
            <div class="container">
                <div class="columns is-vcentered">
                    <div class="column is-10 quote__text-box" v-if="data.content">
                        <p class="quote__text">{{ data.content }}</p>
                    </div>
                </div>
            </div>
    </section>
</template>

<script>
export default {
    data() {
        return {};
    }
};
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"

.quote
    background-repeat: no-repeat
    background-size: 160px
    background-position: center center
    +touch
        padding: $big $medium !important
    +mobile
        display: flex
        flex-direction: column
        text-align: center
    &--blue
        background-color: $blue
        color: $white
        padding: 80px $medium !important
        margin-bottom: $medium
        background-position: 100px center
        +widescreen-only
            background-position: 50px center
        +desktop-only
            background-position: 50px center
        +touch
            background-position: center
            padding: 40px $medium !important
        & .columns
            justify-content: flex-end
            +touch
                justify-content: center
                text-align: center
    &--white
        color: $light-blue
    &--grey
        background-color: $beige
        padding-top: 50px !important
        background-position: center 70px
    &--center
        text-align: center
        justify-content: center
        & .columns
            justify-content: center
    &--extra-padding
        +huge
            padding: 70px 0px
    &--extra-m-top
        .quote__text-box
            margin-top: 3rem
    &--extra-p-top
        .quote__text-box
            padding-top: 4rem !important
            +mobile
                padding-top: 2rem
                padding-bottom: 2rem
    &__text
        font-size: 1.5rem
        font-weight: 500
        +touch
            font-size: 1.4rem
        +mobile
            font-size: 1.3rem
    &__mark
        max-width: 100px
        margin: 0 auto
    &--bottom-0
        margin-bottom: 0
</style>
