<template>
    <section
        class="video section is-paddingless is-marginless"
        :data-aos="data.animation"
        data-aos-duration="1500">
        <div class="video__video-content column is-6 is-paddingless is-marginless">
            <video playsinline="" loop="loop" preload="auto" autoplay="autoplay" muted="muted">
                <source :src="data.video" type="video/mp4">
            </video>
        </div>
        <div v-html="data.content" class="video__text-content column is-6"></div>
    </section>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

// Position of elements on desktop

.video {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: -2px !important;
    @include touch {
        flex-direction: column;
    }
    &__text-content,
    &__video-content {
        display: flex;
    }
    &__text-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 60px;
        @include touch {
            padding: 50px 30px;
            display: block;
        }
        /deep/ h2 {
            margin-bottom: 10px;
            font-size: 1.8rem;
            @include mobile {
                font-family: 1.75rem;
                line-height: 1.25;
            }
        }
        /deep/ p {
            color: #828282;
        }
    }
    &__video-content {
        display: flex;
        width: 100%;
        @include touch {
            display: block;
        }
        video {
            @include touch {
                width: 100%;
            }
        }
    }
    .is-6 {
        @include touch {
            width: 100%;
        }
    }
}
</style>