<template>
    <section class="section contact"
        :class="className"
        :id="`section-${idNumber}`"
        :data-aos="data.animation"
        data-aos-duration="1500"
    >
        <div class="container">
            <div class="contact__content columns is-centered">
                <div class="column is-7 contact__contact-form" :class="`${className}__contact-form`">
                    <h2 class="is-size-3 contact__title" v-html="data.title"></h2>
                    <form @submit.prevent="onSubmit">
                        <div class="field">
                        <label class="contact__label">Name</label>
                        <div class="control">
                            <input
                                class="contact__input-text"
                                type="text"
                                name="name"
                                v-model="name"
                                required
                            >
                        </div>
                        </div>
                        <div class="field">
                        <label class="contact__label">Email</label>
                        <div class="control">
                            <input
                                class="contact__input-text"
                                type="text"
                                name="email"
                                v-model="email"
                                required
                            >
                        </div>
                        </div>
                    <div class="field">
                        <label class="contact__label">Phone number</label>
                        <div class="control">
                            <input
                                class="contact__input-text"
                                type="text"
                                name="name"
                                v-model="phone"
                                required
                            >
                        </div>
                    </div>
                    <div class="field">
                        <label class="contact__label">Enquiry</label>
                        <div class="control">
                            <textarea
                                class="contact__textarea areatextarea"
                                name="name"
                                rows="8"
                                cols="80"
                                v-model="message"
                            ></textarea>
                        </div>
                    </div>
                    <div>
                        <div class="field">
                            <div class="control">
                                This site is protected by reCAPTCHA and the Google
                                <a
                                    href="https://policies.google.com/privacy"
                                >Privacy Policy</a> and
                                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                                <br>
                            </div>
                            <vue-recaptcha
                                ref="recaptcha"
                                @verify="onVerify"
                                @expired="onExpired"
                                size="invisible"
                                :sitekey="sitekey"
                            ></vue-recaptcha>
                            <div class="contact__agree-and-send">
                            <label class="contact__checkbox-container">
                                <input class="contact__checkbox" type="checkbox" checked="checked">
                                <span class="contact__checkmark"></span>
                                <span class="contact__terms-text">GDPR Compliance</span>
                            </label>
                            <div class="contact__button-box control">
                                <hr class="contact__hr">
                                <input class="contact__button" type="submit" name="submit" :value="sendLabel">
                            </div>
                            </div>
                        </div>
                    </div>

                    <transition name="fade" mode="out-in">
                        <article
                            class="message"
                            @click="infoBox.type = 0"
                            v-if="infoBox.type != 0"
                        >
                            <div
                                class="message-body"
                                :class="{'send':infoBox.type == 1,'error':infoBox.type == 2}"
                            >{{ infoBox.content }}
                            </div>
                        </article>
                    </transition>
                </form>
                </div>
                <div class="contact__image-box column is-5" v-if="data.image">
                    <figure class="contact__image image">
                        <img v-lazy="data.image.url" :alt="data.image.alt">
                    </figure>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
export default {
	components: {
		"vue-recaptcha": VueRecaptcha
    },
    data() {
        return {
            name: "",
            email: "",
            message: "",
            phone: "",
            sendLabel: "Submit",
            infoBox: {
                type: 0, // 0 - no, 1 - success, 2 - error
                content: ""
            },
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
        };
    },
    props: {
        data: Object,
        printClass: Function,
        className: String
    },
    methods: {
        onSubmit() {
            this.$refs.recaptcha.execute();
            this.sendLabel = "Sending...";
        },
        onVerify(recaptchaToken) {
            this.$refs.recaptcha.reset();
            var bodyFormData = new FormData();
            bodyFormData.set("name", this.name);
            bodyFormData.set("email", this.email);
            bodyFormData.set("phone", this.phone);
            bodyFormData.set("message", this.message);
            bodyFormData.set("recaptchaToken", recaptchaToken);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        axios
            .post(
                process.env.VUE_APP_API + "wp-json/better-api/v1/send",
                bodyFormData,
                config
            )
            .then(response => {
                if (response.data == "ok") {
                    this.infoBox.type = 1;
                    this.infoBox.content = "Email was send successfully.";
                    this.sendLabel = "Send";
                    setTimeout(() => {
                        this.infoBox.type = 0;
                    }, 3000);
                } else {
                    this.infoBox.type = 2;
                    this.infoBox.content = "Server side error";
                    this.sendLabel = "Send";
                    setTimeout(() => {
                        this.infoBox.type = 0;
                    }, 3000);
                }
                })
                .catch(err => {
                    this.infoBox.type = 2;
                    this.infoBox.content = "Cannot send email, please try again later.";
                    this.sendLabel = "Send";
                setTimeout(() => {
                    this.infoBox.type = 0;
                }, 3000);
                console.log(getErrorMessage(err));
                //helper to get a displayable message to the user
                function getErrorMessage(err) {
                    let responseBody;
                    responseBody = err.response;
                    if (!responseBody) {
                        responseBody = err;
                    } else {
                        responseBody = err.response.data || responseBody;
                    }
                    return responseBody.message || JSON.stringify(responseBody);
                }
            });
        },
        onExpired() {
        }
    }
};
</script>

<style lang="sass">
@import "@/styles/framework/variables.sass"

.message-body
    border-radius: 0
.send
    border-color: green !important
.error
    border-color: red !important

.contact
	background-color: $beige
	box-sizing: border-box
	padding-top: 50px !important
	padding-bottom: 50px !important
	&__title
		+mobile
			text-align: center
	&__column-image
        display: flex !important
        justify-content: flex-end !important
        align-items: flex-end !important
	&__contact-form
		+touch
			width: 100% !important
			max-width: 800px !important
	&__title
		color: $dark-blue
		margin-bottom: $medium
	&__label
		color: $dark-blue
		font-weight: 300
		margin-bottom: $medium
	&__input-text
		background-color: $beige
		width: 100%
		padding: 6px 0
		border: none
		border-bottom: 1px solid $light-grey
		color: $dark-grey
		outline: none
		font-size: 1rem
	&__textarea
		background-color: $beige
		width: 100%
		padding: 6px 0
		border: none
		border-bottom: 1px solid $light-grey
		color: $dark-grey
		outline: none
		font-size: 1rem
		height: 200px
		resize: none
	&__agree-and-send
		align-items: center
		margin-top: .9375rem
		justify-content: space-between
	&__button-box
		display: flex
		align-items: center
	&__hr
		width: $medium
		background-color: $dark-blue
		height: 1px
		margin-right: 5px
	&__button
		background-color: $beige
		font-size: 1rem
		font-weight: 300
		text-transform: uppercase
		border: none
		cursor: pointer
		outline: none
		transition: color $fast ease-in-out
		&:hover
			color: $blue
	&__checkbox-container
		display: block
		position: relative
		padding-left: 35px
		cursor: pointer
		font-size: 1.375rem
		user-select: none
	&__checkbox-container:hover input ~ .contact__checkmark
		background-color: #ccc
	&__checkbox-container input:checked ~ .contact__checkmark
		background-color: $dark-blue
	&__checkbox
		position: absolute
		top: 0px
		left: 0px
	&__checkmark
		position: absolute
		border: 1px solid $dark-blue
		top: 0
		left: 0
		height: $medium
		width: $medium
		background-color: $white
		transition: background-color $fast ease-in-out
		&:after
			content: ""
			position: absolute
			display: none
	&__checkbox-container input:checked ~ .contact__checkmark:after
		display: block
	&__checkbox-container .contact__checkmark:after
		left: 7px
		top: 3px
		width: 5px
		height: 10px
		border: solid white
		border-width: 0 3px 3px 0
		transform: rotate(45deg)
	&__terms-text
		display: flex
        color: $primary
		font-size: .875rem
		& > a
			color: $primary
			font-size: .875rem
.contact
    &__content
        +touch
            flex-direction: column
    &__image
        width: 250px
    &__image-box
        display: flex !important
        justify-content: center
        align-items: center

.grecaptcha-badge
	visibility: hidden

</style>
