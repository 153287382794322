<template>
	<div class="big-hero" :style="{backgroundImage: 'url(' + data.image + ')' }">
		<p v-html="data.title"></p>
	</div>
</template>

<script>
export default {
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.big-hero{
	height: 563px;
	width: 100%;
	background-size: cover;
	background-position: center;
	position: relative;
	p{
		position: absolute;
		z-index: 20;
		color: #FFF;
		font-size: 28px;
		padding: 0 50px;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		text-align: center;
		max-width: 934px;
		font-weight: 400;
		width: 100%;
		color:#262647;
		::v-deep span{
			color: #297CDC !important;
		}
	}
	&__apla{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #2279DF;
		mix-blend-mode: multiply;
	}
	svg{
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
}
</style>
