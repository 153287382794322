<template>
	<div class="big-hero" :style="{backgroundImage: 'url(' + data.image + ')' }">
		<div class="big-hero__apla"></div>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 683 374" style="enable-background:new 0 0 683 374;" xml:space="preserve">
			<path class="st0" d="M227.5,225c10.8,18.6,27,21.8,36.5,21.8c14.1,0,25.7-9.5,31.8-19.4l0,0c0.3-0.7,0.9-1.1,1.7-1.4
				c0.5-0.2,1.1-0.2,1.7-0.1l-84.5,146.9l-0.4-0.8l0,0l0,0L1,1.4C0.8,1,0.7,0.8,0.6,0.5h169.1c-0.3,1.4-1.5,2.5-3,2.5h-0.5h0
				c-1.6,0-3,0-4.3,0.3c-5.2,0.5-10.1,2.2-14.4,4.9c-4.4,2.5-8.4,6-11.4,10.1c-3,4.1-5.2,8.5-6.5,13.4c-1.4,4.9-1.6,9.8-1.1,14.7
				c0.5,5.2,2.2,10,4.9,14.4C135.2,64.1,213.6,201.1,227.5,225z"/>
			<path class="st0" d="M479,225c10.8,18.6,27,21.8,36.5,21.8c14.1,0,25.7-9.5,31.8-19.4l0,0c0.3-0.7,0.9-1.1,1.7-1.4
				c0.5-0.2,1.1-0.2,1.7-0.1l-84.5,146.9l-0.4-0.8l0,0l0,0L252.5,1.4c-0.2-0.3-0.3-0.6-0.4-0.9h169.1c-0.3,1.4-1.5,2.5-3,2.5h-0.5h0
				c-1.6,0-3,0-4.3,0.3c-5.2,0.5-10.1,2.2-14.4,4.9c-4.4,2.5-8.4,6-11.4,10.1c-3,4.1-5.2,8.5-6.5,13.4c-1.4,4.9-1.6,9.8-1.1,14.7
				c0.5,5.2,2.2,10,4.9,14.4C386.7,64.1,465.1,201.1,479,225z"/>
			<path class="st0" d="M484.1,1.4L484.1,1.4L484.1,1.4c-0.1-0.2-0.1-0.3-0.2-0.4h197.6l-98.8,171c-1.2-1-1.6-2.7-0.8-3.8l0,0l0,0
				c7.3-13,11.9-26.8,13.8-41.4l0,0c1.6-14.1,0.8-28.2-3-42c-3.5-13.8-9.7-26.5-18.4-37.9c-8.9-11.9-19.7-21.7-32.7-29
				c-13-7.3-26.7-11.9-41.3-13.8l0,0c-4.3-0.5-8.9-0.8-13.5-0.8C485.7,3.3,484.5,2.6,484.1,1.4z"/>
		</svg>

		<p v-html="data.title"></p>
	</div>
</template>

<script>
export default {
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.st0{fill:none;stroke:#2279DF;}
.big-hero{
	height: 563px;
	width: 100%;
	background-size: cover;
	position: relative;
	background-position: center;
	p{
		position: absolute;
		z-index: 20;
		color: #FFF;
		font-size: 28px;
		padding: 0 50px;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		text-align: center;
		max-width: 934px;
		font-weight: 400;
		width: 100%;
	}
	&__apla{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #2279DF;
		mix-blend-mode: multiply;
	}
	svg{
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		max-width: 700px;
	}
}
</style>
