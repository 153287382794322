<template>
    <section class="quote section"
        :data-aos="data.animation"
        data-aos-duration="1500"
        :class="data.custom_class"
        :style="{backgroundColor: data.background_color}">
        <div class="container">
            <div class="columns" :style="{flexDirection: data.order}">
                <div v-html="data.content" class="quote__text-box column"></div>
                <div class="quote__image-box column">
                    <figure class="image">
                        <img :src="data.logo.url" :alt="data.logo.alt">
                    </figure>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {};
    }
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.quote.white-text {
    color: #fff;
    .quote__text-box {
        /deep/ h1,
        /deep/ h2,
        /deep/ h3,
        /deep/ p,
        /deep/ span,
        /deep/ a {
            color: #fff;
        }
        /deep/ a {
            color: #fff;
            &::before {
                content: '';
                width: 20px;
                height: 1px;
                background-color: #fff;
                display: block;
                position: absolute;
                left: -30px;
                top: 11px;
            }
        }
    }
}
.quote {
    padding: 80px 40px !important;
    @include touch {
        padding: 40px 30px !important;
    }
    &__text-box {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        /deep/ h2 {
            margin-bottom: 10px;
            font-size: 1.8rem;
            @include mobile {
                font-family: 1.75rem;
                line-height: 1.25;
            }
        }
        /deep/ p {
            color: #828282;
        }
        /deep/ a {
            color: #262647;
            text-transform: uppercase;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            position: relative;
            margin-top: 10px;
            right: -30px;
            &::before {
                content: '';
                width: 20px;
                height: 1px;
                background-color: #262647;
                display: block;
                position: absolute;
                left: -30px;
                top: 11px;
            }
        }
    }
    &__image-box {
        display: flex;
        align-items: center;
        justify-content: center;
        @include touch {
            display: none;
        }
        img {
            margin: 0 auto;
            width: 400px;
            @include touch {
                width: 250px;
            }
        }
    }
}
</style>
