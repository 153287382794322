<template>
    <section
        class="hero-image section"
        :class="[data.custom_class]"
        :data-background-image="data.background_image"
    >
        <div class="container">
            <div class="columns">
                <div class="column is-12">
                    <h2 class="hero-image__title title is-size-4" v-html="data.title">{{ data.title }}</h2>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"

.hero-image
	display: flex
	padding: 3rem 6.5rem
	height: 600px
	box-sizing: border-box
	background-size: cover
	align-items: center
	background-position: center
    font-weight: 500
	+touch
		padding: 3rem 1rem
		max-height: calc(100vh - 85px)
	/deep/.container
		display: flex
	&--text-right
		align-items: center
		/deep/.container
			justify-content: flex-end
		/deep/.columns
			max-width: 700px
	&--text-bottom
		flex-direction: column-reverse
		padding-bottom: 200px
		/deep/.container
			justify-content: center
		/deep/.columns
			text-align: center
			max-width: 1000px
	&--text-left
		align-items: center
		/deep/.container
			justify-content: flex-start
		/deep/.columns
			max-width: 700px
	&--white
		/deep/.hero-image__title
			color: $white
	&__title
        color: $dark-blue
        font-weight: 500
        line-height: 1.35
        +touch
            font-size: 1.25rem !important
</style>
