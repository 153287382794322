<template>
  <section class="flickity slider" :id="`section-${idNumber}`" :class="[data.custom_class]">
    <flickity ref="flickity" :options="flickityOptions">
      <div class="carousel-cell" v-for="(cell, index) in data.singlecell" :key="index">
        <div class="container">
          <div class="columns slider__columns">
            <div class="column slider__text-box">
              <div class="slider__nav-box">
                <span class="slider__main-title" v-if="data.main_title">{{ data.main_title }}</span>
                <span class="slider__main-separator" v-if="data.main_title">/</span>
                <span
                  class="slider__single-title"
                  v-for="(single_title, index) in data.single_titles"
                  :key="index"
                >{{ single_title.single_title }}</span>
              </div>
              <h2 class="title is-size-3 slider__title" v-if="cell.title">{{ cell.title }}</h2>
              <p class="slider__paragraph" v-if="cell.description">{{ cell.description }}</p>
            </div>
            <div class="column slider__image-box" v-if="cell.image">
              <picture>
                <source media="(max-width: 1408px)" :srcset="cell.image.sizes.medium_large">
                <source media="(max-width: 2500px)" :srcset="cell.image.sizes.large">
                <img
                  class="text-and-image__image"
                  v-lazy="cell.image.sizes.large"
                  :alt="cell.image.alt"
                >
              </picture>
            </div>
          </div>
        </div>
      </div>
    </flickity>
  </section>
</template>

<script>
import Flickity from "vue-flickity";

export default {
    data() {
        return {
        flickityOptions: {
            // Some options
            initialIndex: 3,
            wrapAround: true,
            // pageDots: false
        }
        };
    },
    components: {
        Flickity
    },
    methods: {
        next() {
        this.$refs.flickity.next();
        },
        previous() {
        this.$refs.flickity.previous();
        }
    }
};
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"

/deep/ .flickity
    text-align: center
    color: $white
    position: relative

/deep/ .flickity-enabled
    position: relative
    +mobile
        height: calc(100vh - 50px)

/deep/ .flickity-button
    position: absolute
    bottom: 0
    top: auto
    background-color: $blue
    border-radius: 0
    outline: none
    +touch
        display: none

/deep/ .flickity-viewport
    height: 610px !important
    +touch
        height: 400px !important

/deep/ .previous
    left: 25px

/deep/ .next
    left: 70px

/deep/ .flickity-button-icon
    color: $white

/deep/ .carousel-cell
    padding: 0px 0px 50px

/deep/ .flickity-page-dots
    display: none
    +touch
        display: block
        bottom: 50px

.slider
    padding: 0
    height: 610px
    +touch
        height: auto
        margin: 0 auto
        max-width: 840px

    &__title
        color: $dark-blue

    &__nav-box
        width: 100%
        margin-bottom: 15px
        text-transform: uppercase
        display: flex
        align-items: center
        flex-direction: flex-start

    &__main-title
        color: $dark-blue

    &__paragraph,
    &__single-title,
    &__main-separator
        color: $primary

    &__main-separator
        display: block
        padding: 0px 10px

    &__single-title
        position: relative
        margin: 0 10px
        +mobile
            font-size: .8rem
        &:last-child::after
            display: none

        &::after
            position: absolute
            display: block
            content: ""
            background-color: $primary
            border-radius: 50%
            height: 2px
            width: 2px
            top: 12px
            right: -12px

    &__text-box
        text-align: left
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column

    &__image-box
        +touch
            display: none

    .carousel-cell
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        box-sizing: border-box
        text-align: center
        flex-direction: column
        padding: 50px $medium
        +mobile
        //height: calc(100vh - 50px)


</style>
