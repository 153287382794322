<template>
  <section class="blog">
    <!-- <div class="blog__header">
        <div class="blog__header-box">
            <div class="blog__header-content">
                <div>
                    <h1>Media appearance</h1>
                    <p>Ficimus, qui simaio tem. Ficia aut eat modicab ium event quidi dolupta
                    volorerit quibea net hiligen ianisqu atessimus, suntiis venit eossus
                    explaborerum sae eum atem. Ad qui voles evenducipsum eaquamu
                    sandiciis ea velluptae. Rovitecuptur aut volupta temquibus, ommodit et
                    aditas eaquia velectem liberov idebitiis net lacerum eum quam qui alitas
                    placcus dandige nimodis dolor sam, ut est, adit, odici temodi unt porum</p>
                    <a href="#"><div class="header__button">Read more</div></a>
                </div>
            </div>
            <div class="blog__header-image">
                <div>
                    <img src="../../assets/WS.jpg" alt="">
                </div>
            </div>
        </div>
    </div> -->
    <div class="blog__post-box">
      <div class="blog__post" v-for="post in posts">
        <div class="blog__post-video">
          <video :src="post.video_thumbnail" controls></video>
        </div>
        <div class="blog__post-content">
          <div class="case">
            <span>{{ post.post_date }}</span>
            <h1>{{ post.title }}</h1>
            {{ post.excerpt }}
            <router-link class="blog__button" :to="'/post' + post.link"
              >Read more
            </router-link>
          </div>
        </div>
      </div>

      <div class="blog__buttons">
        <router-link :to="'/'" class="blog__prev">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160    c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125    c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z    "
                      data-original="#000000"
                      class="active-path"
                      data-old_color="#000000"
                      fill="#FFFFFF"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <span>HOME</span>
          </div>
        </router-link>
        <router-link :to="'/about'" class="blog__next">
          <div>
            <span>ABOUT</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M508.875,248.458l-160-160c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l141.792,141.792    H10.667C4.771,245.333,0,250.104,0,256s4.771,10.667,10.667,10.667h464.917L333.792,408.458c-4.167,4.167-4.167,10.917,0,15.083    c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l160-160C513.042,259.375,513.042,252.625,508.875,248.458z    "
                      data-original="#000000"
                      class="active-path"
                      data-old_color="#000000"
                      fill="#FFFFFF"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    posts() {
      return this.$store.getters.getPosts.slice(0, 3);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
// .post-list
// 	background-color: #dcdcdc
// 	width: 100%
// 	margin: 0 auto
// 	max-width: 1600px
.blog {
  height: 100%;
  margin-top: 24px;
  &__header {
    background-color: $beige;
    margin-bottom: 120px;
  }
  &__header-box {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
  }
  &__header-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;
    padding-left: 0;
    div {
      h1 {
        color: $dark-blue;
        font-size: 28px;
      }
      p {
        color: gray;
        padding: 10px 0;
        font-size: 16px;
      }
    }
  }
  &__header-image {
    flex: 1;
    position: relative;
    img {
      position: absolute;
      top: 70px;
      height: 100%;
      width: 100%;
    }
  }
  &__post-box {
    margin-right: 40px;
    @include touch {
      margin: 0;
    }
  }
  &__post {
    display: flex;
    margin-top: 60px;
    @include touch {
      flex-direction: column !important;
      padding: 60px;
      margin: 0;
    }
    @include mobile {
      padding: 20px;
    }
    .blog__post-content {
      .case {
        padding: 60px;
        @include desktop-only {
          padding: 40px;
        }
        @include touch {
          padding: 30px 30px 0 30px;
        }
      }
    }
  }
  &__post:nth-child(odd) {
    .blog__post-content {
      padding-left: 30px;
      @include touch {
        padding: 0;
      }
    }
  }
  &__post:nth-child(even) {
    flex-direction: row-reverse;
    background-color: $beige;
    .blog__post-content {
      padding-right: 30px;
      @include touch {
        padding: 0;
      }
    }
  }
  &__post-box:last-child {
    margin-bottom: 20px;
  }
  &__post-video {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
      width: 100%;
    }
  }
  &__post-content {
    flex: 1;
    display: flex;
    align-items: center;
    .case {
      span {
        color: gray;
      }
      h1 {
        color: $dark-blue;
        font-size: 28px;
      }
      p {
        color: gray;
        padding: 10px 0;
        font-size: 15px;
      }
    }
  }
  &__button {
    color: $dark-blue;
    text-transform: uppercase;
    position: relative;
    padding-left: 20px;
    &::before {
      content: "";
      height: 1px;
      width: 14px;
      background-color: $dark-blue;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    @include touch {
      display: none;
    }
    a {
      padding: 10px 20px;
      color: #fff;
      div {
        display: flex;
        align-items: center;
      }
    }
    svg {
      height: 30px;
      width: 30px;
    }
  }
  &__prev {
    background-color: $light-grey;
    div {
      span {
        padding-left: 10px;
        font-size: 20px;
      }
    }
  }
  &__next {
    background-color: $light-blue;
    div {
      span {
        padding-right: 10px;
        font-size: 20px;
      }
    }
  }
}
</style>

<!-- <div class="container">
    <div class="columns">
        <div class="column" v-html="data.content"></div>
    </div>
</div>
<div class="container">
    <div
        class="columns is-vcentered post-list__item"
        v-for="(item, key) in data.posts"
        :key="key"
    >
        <div
        class="column image-wrapper"
        :class="[ (key % 2 == 0) ? 'is-7-widescreen' : 'is-7-widescreen is-offset-1-widescreen' ]"
        :style="{ order: (key % 2 == 0) ? 1 : 2 }"
        >
            <figure class="image is-4by3" v-if="item.thumbnail">
                <img v-lazy="item.thumbnail" :alt="item.title" @click="$router.push(item.link)">
            </figure>
        </div>
        <div
        class="column content-wrapper"
        :class="[ (key % 2 == 0) ? 'is-4-widescreen is-offset-1-widescreen' : 'is-4-widescreen' ]"
        :style="{order: (key % 2 == 0) ? 2 : 1}"
        >
            <h1 class="subtitle" @click="$router.push(item.link)" v-html="item.title"></h1>
            <div v-html="item.excerpt"></div>
            <a :href="item.link" @click.prevent="$router.push(item.link)">More</a>
        </div>
    </div>
</div>
<div class="container">
    <div class="columns">
        <div class="column has-text-centered">
            <router-link :to="`/${postsPage}/`" class="button">See all Posts</router-link>
        </div>
    </div>
</div> -->
