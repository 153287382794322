<template>
  <section class="video-post__section">
    <div class="video-post__video-container video-post__video-container--hero">
      <video
        controls
        :src="post.video_thumbnail"
        class="video-post__video video-post__video--hero"
        @click="playVideo"
      ></video>
      <div class="video-post__text-container video-post__text-container--hero">
        <p class="video-post__date video-post__date--hero">
          {{ post.post_date }}
        </p>
        <p class="video-post__title video-post__title--hero">
          {{ post.title }}
        </p>
        <p
          class="video-post__text video-post__text--hero"
          v-html="post.content"
        ></p>
      </div>
    </div>

    <template v-if="!desktopView">
      <p class="video-post__subtitle">Proponowane</p>
      <div class="video-post__mobile-container">
        <template v-for="(post, index) in posts" id="">
          <router-link
            :key="index"
            :to="'/post' + post.link"
            class="video-post__video-container"
          >
            <img
              :src="post.thumbnail"
              class="video-post__video-img"
              :data-aos="'fade-down'"
            />
            <div class="video-post__text-container" :data-aos="'fade-up'">
              <p class="video-post__date">{{ post.post_date }}</p>
              <p class="video-post__title">
                {{ post.title }}
              </p>
              <p class="video-post__text">
                {{ post.excerpt | isExcerptNotToLong }}
              </p>
            </div>
          </router-link>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="carousel-wrapper">
        <p class="video-post__subtitle">Proponowane</p>

        <Carousel
          :paginationEnabled="false"
          :navigationEnabled="true"
          :navigationNextLabel="''"
          :navigationPrevLabel="''"
        >
          <Slide v-for="(post, index) in posts" :key="index">
            <router-link
              :to="'/post' + post.link"
              class="video-post__video-container"
            >
              <img
                :src="post.thumbnail"
                class="video-post__video-img"
                :data-aos="'fade-down'"
              />
              <div class="video-post__text-container" :data-aos="'fade-up'">
                <p class="video-post__date">{{ post.post_date }}</p>
                <p class="video-post__title">
                  {{ post.title }}
                </p>
                <p class="video-post__text">
                  {{ post.excerpt | isExcerptNotToLong }}
                </p>
              </div>
            </router-link>
          </Slide>
        </Carousel>
      </div>
    </template>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  data() {
    return {
      windowWidth: 0,
      desktopView: false,
      videoElement: null
    };
  },
  components: {
    Carousel,
    Slide
  },
  methods: {
    isDesktopView() {
      if (this.windowWidth >= 1023) {
        this.desktopView = true;
      } else {
        this.desktopView = false;
      }
    },
    isCurrentPostIsMain(post) {
      let simplePostSlug = "/post/" + post.slug;
      let routeSlug = this.$route.path;
      if (simplePostSlug !== routeSlug) {
        return true;
      } else {
        return false;
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
      this.isDesktopView();
    },
    playVideo(event) {
      event.target.play();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.isDesktopView();
  },
  computed: {
    posts() {
      const loadedPosts = this.$store.getters.getPosts;
      const filtredPosts = loadedPosts.filter(this.isCurrentPostIsMain);
      return filtredPosts;
    },
    post() {
      return this.$store.getters.getPostByPath(this.$route.path);
    }
  },
  filters: {
    isExcerptNotToLong(text) {
      let textLength = text.length;
      let maxTextLength = 150;
      if (textLength > maxTextLength) {
        let newText = text.slice(0, maxTextLength).concat("...");
        return newText;
      } else {
        return text;
      }
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap");
.video-post {
  &__section {
    padding: 22px 0 5% 0;
    @media (min-width: 754px) {
      margin-left: 100px;
    }
  }
  &__video {
    width: 10%;
    min-height: 100%;
    align-self: center;
    &--hero {
      margin: 0 0 15px 0;
      min-width: 100%;
      cursor: pointer;
      @media (min-width: 1023px) {
        min-width: 50%;
        margin: 0 15px 0 0;
      }
    }
  }
  &__video-img {
    min-height: 100%;
    flex: 1;
    width: 50%;
    margin: 0 15px 0 0;
    object-fit: cover;
    object-position: center;
    @media (min-width: 1023px) {
      margin: 0;
    }
  }
  &__date {
    font-family: "Roboto";
    font-size: 10px;
    color: #b4b4b4;
    font-weight: 100;
    &--hero {
      padding: 0 5% 0;
      @media (min-width:1023px){
        padding: 0 5% 0;

      }
    }
    @media (min-width:1023px){
      font-size: 18px;
    }
  }
  &__title {
    font-family: "Roboto";
    font-size: 16px;
    color: #000;
    font-weight: 500;
    &--hero {
      padding: 0 5% 0;
    }
    @media (min-width:1023px){
      font-size: 34px;
    }
  }
  &__text {
    font-family: "Roboto";
    font-size: 14px;
    color: #787878;
    &--hero {
      padding: 0 5% 0;
    }
    @media (min-width:1023px){
      font-size: 17px;
    }
  }
  &__subtitle {
    font-family: "Roboto";
    font-size: 16px;
    color: #787878;
    padding: 0 10% 20px;
    @media (min-width: 1023px) {
      font-size: 18px;
      font-weight: 500;
      padding: 0 0 20px;
      margin-bottom: 30px;
    }
  }
  &__text-container {
    flex: 1;
    @media (min-width: 1023px) {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-right: 30px;
    }
    &--hero {
      justify-content: center;
      padding-right: 0;
      @media (min-width: 1023px) {
        padding-top: 5%;
      }
    }
  }
  &__video-container {
    display: flex;
    width: 80%;
    padding: 0;
    margin: 0 auto 20px;
    cursor: pointer;
    @media (min-width: 1023px) {
      flex-direction: row-reverse;
      width: 100%;
    }
    &--hero {
      width: 100%;
      max-width: initial;
      cursor: initial;
      flex-direction: column;
      background-color: #efefef;
      padding: 0 0 5%;
      margin: 0 0 5%;
      @media (min-width: 1023px) {
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        padding: 20px 2%;
      }
      @media (min-width: 1600px) {
        padding: 20px 2%;
      }
    }
  }
}

.VueCarousel-navigation-next,
.VueCarousel-navigation-prev {
  background-color: #2179df !important;
  width: 50px !important;
  height: 50px !important;
  color: #fff !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://szczepaniak.ch/cms/app/uploads/2019/07/ARROW.svg");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}
.VueCarousel-navigation-next {
  right: -20px !important;
  transform: translateY(-50%) translateX(100%) rotate(180deg) !important;
}
.VueCarousel-navigation-prev {
  left: -20px !important;
}
.VueCarousel-slide {
  padding: 0 20px;
}

.carousel-wrapper {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}
</style>
