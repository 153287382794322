<template>
    <header>
        <div
        class="navbar is-fixed-top header"
        aria-label="main navigation"
        id="main-nav"
        >
        <div class="navbar-brand">
            <logo />
            <a
            role="button"
            aria-label="menu"
            aria-expanded="false"
            class="burger"
            :class="{'is-active': burger}"
            @click="burger = !burger"
            >
            <span></span>
            <span></span>
            <span></span>
            </a>
        </div>

        <div class="header__nav-box">
            <div class="navbar-end header__social-media">
                <!-- <a class="header__box-icon" href="mailto:adres e-mail">
                    <svg class="header__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <title>MAIL</title>
                    <path
                        class="a"
                        d="M0,3.5v23H30V3.5Zm27,3V8.34L15,16.67,3,8.34V6.5ZM3,23.5V12l12,8.34L27,12V23.5Z"
                    ></path>
                    </svg>
                </a>
                <a class="header__box-icon" href="https://www.facebook.com/">
                    <svg class="header__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <title>FB</title>
                    <path
                        class="a"
                        d="M26.5,7.33A4,4,0,0,0,22.67,3.5H7.33A4,4,0,0,0,3.5,7.33V22.67A4,4,0,0,0,7.33,26.5H15V17.81H12.19V14H15v-1.5c0-2.57,1.93-4.89,4.31-4.89h3.1v3.83h-3.1c-.34,0-.73.41-.73,1V14h3.83v3.83H18.58V26.5h4.09a4,4,0,0,0,3.83-3.83Z"
                    ></path>
                    </svg>
                </a>
                <a class="header__box-icon" href="https://www.instagram.com/">
                    <svg class="header__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <title>IG</title>
                    <path
                        class="a"
                        d="M15.27,11.68a3.88,3.88,0,1,0,3.87,3.88,3.88,3.88,0,0,0-3.87-3.88m0,10.07a6.2,6.2,0,1,1,6.19-6.19,6.2,6.2,0,0,1-6.19,6.19"
                    ></path>
                    <path class="a" d="M23,9.08a1.4,1.4,0,1,1-1.4-1.4A1.4,1.4,0,0,1,23,9.08"></path>
                    <path
                        class="a"
                        d="M24.34,10.36a4,4,0,0,0-4-4H9.94a4,4,0,0,0-4,4V20.75a4,4,0,0,0,4,4h10.4a4,4,0,0,0,4-4Zm2.3,10.37a6.33,6.33,0,0,1-6.33,6.33H10a6.33,6.33,0,0,1-6.32-6.33V10.38A6.32,6.32,0,0,1,10,4.06H20.31a6.32,6.32,0,0,1,6.33,6.32Z"
                    ></path>
                    </svg>
                </a>
                <a class="header__box-icon" href="https://www.linkedin.com/">
                        <svg class="header__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                        <title>IN</title>
                        <path
                            class="a"
                            d="M4,26.65h5v-15H4ZM6.47,4.46A2.47,2.47,0,0,0,3.89,7,2.47,2.47,0,0,0,6.41,9.63h0A2.48,2.48,0,0,0,9,7,2.47,2.47,0,0,0,6.47,4.46M26.89,18.07v8.58h-5v-8c0-2-.72-3.39-2.52-3.39a2.73,2.73,0,0,0-2.56,1.83,3.41,3.41,0,0,0-.16,1.21v8.36h-5s.06-13.57,0-15h5V13.8l0,0h0v0a4.93,4.93,0,0,1,4.48-2.48c3.28,0,5.74,2.14,5.74,6.75"
                        ></path>
                        </svg>
                </a> -->
            </div>

        <div class="header__burger-box" id="header-burger-box">
            <span class="header__burger-item"></span>
            <span class="header__burger-item"></span>
            <span class="header__burger-item"></span>
        </div>

        </div>

            <nav class="header__nav">
                <div
                    class="header__item navbar-item"
                    @click="activeSubmenu = (activeSubmenu == menuKey) ? 0 : menuKey"
                    :class="{'has-dropdown is-hoverable':menuItem.children, 'is-active':activeSubmenu == menuKey}"
                    v-for="(menuItem, menuKey) in mainMenu"
                    :key="menuKey"
                >
                    <a
                    class="header__menu-item"
                    :class="{'menu-item':!menuItem.children, 'navbar-link':menuItem.children}"
                    :href="'/'+menuLocale+menuItem.url"
                    @click.prevent="menuItem.children ? '' : navigateTo('/'+menuItem.url)"
                    v-html="menuItem.title"
                    ></a>

                </div>
            </nav>
        </div>
    </header>
</template>

<script>
import logo from "./flex/Logo";

export default {
    data() {
        return {
            burger: false,
            menuLang: false,
            activeSubmenu: 0
        };
    },
    components: {
        logo
    },
    created() {},
    methods: {
        langHomeUrl(home_url) {
            return this.publicPath + home_url;
        },
        navigateTo(url) {
            const publicPath = "/" + this.$store.getters.getPublicPath;
            const locale = this.$store.getters.getLocale;
            if (url != "#") {
                this.$router.push(url);
                this.burger = false;
            }
        },
        handleScroll() {
            if (window.scrollY) {
                document.body.classList.add("active-scroll");
            } else {
                document.body.classList.remove("active-scroll");
            }
        },
        handleBurger() {
            const header = document.querySelector('#main-nav');

            if(header.classList.contains("active")) {
                header.classList.remove("active");
            } else {
                header.classList.add("active");
            }
        }
    },
    computed: {
        languages() {
            return this.$store.getters.getLanguages;
        },
        options() {
            return this.$store.getters.getOptions;
        },
        locale() {
            return this.$store.getters.getLocale;
        },
        menuLocale() {
            return this.locale != this.$store.getters.getOptions.default_language
                ? this.locale + "/"
                : "";
        },
        publicPath() {
            return this.$store.getters.getPublicPath;
        },
        mainMenu() {
            return this.$store.getters.getMainMenu;
        }
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    mounted() {
        const hamburger = document.querySelector('#header-burger-box');
        const menuLinks = Array.from(document.querySelectorAll('.header__item'));

        menuLinks.forEach(menuLink => menuLink.addEventListener("click", this.handleBurger));
        hamburger.addEventListener("click", this.handleBurger);
    },
    destroyed() {
        window.addEventListener("scroll", this.handleScroll);
    }
};
</script>

<style scoped lang="sass">
@import "@/styles/framework/variables.sass"

.navbar
    box-sizing: border-box
    padding: 30px 35px 30px $medium
    background-color: $white
    transition: background-color $fast ease-in-out, height $fast
    height: 122.05px
    +mobile
        padding: $medium $small $medium $small
.header
    display: flex
    align-items: center
    justify-content: space-between
    max-width: 100%
    box-sizing: border-box
    &.active &__nav
        opacity: 1
        transform: translateY(0%)
    &:hover .header__burger-item
        background-color: $light-blue
    &.active .header__burger-item:nth-child(1)
        transform: rotate(45deg) translate(2px, -1px)
    &.active .header__burger-item:nth-child(2)
        opacity: 0
    &.active .header__burger-item:nth-child(3)
        transform: rotate(-45deg) translate(0px, 0px)
    &__nav-box
        display: flex
        margin-right: 15px
    &__item
        justify-content: center
        display: flex
    &__menu-item
        font-size: 1.5rem
    &__logo
        height: auto
        width: 250px
        max-height: none
        transition: all $fast ease-in-out
        +mobile
            width: 200px
        & .b,
        & .c
            fill: $light-blue
    &__burger-box
        display: none
        flex-direction: column
        margin-left: $medium
        cursor: pointer
        +mobile
            display: flex
    &__burger-item
        width: 33px
        height: 2px
        margin-bottom: 7px
        position: relative
        background-color: $dark-blue
        transform-origin: 4px 0px
        transition: all $fast ease-in-out
        display: block
    &__social-media
        display: flex
        justify-content: center
        align-items: center
        +mobile
            display: none
    &__box-icon
        display: flex
        justify-content: center
        align-items: center
        padding: 2px
        margin: 0px 6px
    &__icon
        height: $medium
        fill: #b4b4b4
        transition: fill $fast ease-in-out
        &:hover
            fill: $light-blue !important
    &__nav
        display: flex
        position: absolute
        z-index: 9999
        background-color: $white
        height: 100vh
        width: 100%
        top: 80px
        left: 0
        box-sizing: border-box
        padding: 2rem
        flex-direction: column
        justify-content: center
        align-items: center
        padding-bottom: 114.5px
        opacity: 0
        transition: opacity $fast ease-in-out
        transition: transform $slow ease-in-out
        transform: translateX(120%)
body.active-scroll .navbar
    background-color: $dark-blue
    height: 80px
body.active-scroll .header
    &__logo
        height: 50px
body.active-scroll .header__burger-item
    background-color: $white
body.active-scroll .header__icon,
body.active-scroll .header__logo .a
    fill: $white

#main-nav
    z-index: 9999

@media screen and (max-height: 600px)
    .header
        &__burger-box
            display: flex
</style>
