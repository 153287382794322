<template>
<div class="slider">
	<section class="main-slider"
	         :class="[data.custom_class]"
	         :data-aos="data.animation"
	         data-aos-duration="1500">
		<div class="main-slider__container">
			<transition-group tag="div"
			                  :name="transitionName"
			                  class="slides-group">
				<div v-if="show"
				     :key="current"
				     class="main-slider__slide"
				     :class="data.singlecell[current].title">
					<div class="container">
						<div class="columns main-slider__columns">
							<div class="column main-slider__column">
								<div class="main-slider__content">
									<p class="is-size-4">{{ data.singlecell[current].content }}</p>
									<img :src="data.singlecell[current].logo.url"
									     :alt="data.singlecell[current].logo.alt">
									<span><a :href="data.singlecell[current].link_url"
										   @click.prevent="navigateTo('/' + data.singlecell[current].link_url)">{{ data.singlecell[current].link_title }}</a></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition-group>
		</div>
		<div class="main-slider__buttons">
			<a @click.prevent="slide(1)"><img src="https://szczepaniak.ch/cms/app/uploads/2019/07/ARROW.svg"></a>
			<a @click.prevent="slide(-1)"><img src="https://szczepaniak.ch/cms/app/uploads/2019/07/ARROW.svg"></a>
		</div>
	</section>
</div>
</template>

<!-- data.singlecell -->

<script>
export default {
	data() {
		return {
			current: 0,
			show: false,
			direction: 1,
			transitionName: "fade"
		}
	},
	methods: {
		slide(dir) {
			this.direction = dir;
			dir === 1 ? (this.transitionName = "slide-next") : (this.transitionName = "slide-prev")
			var len = this.data.singlecell.length
			this.current = (this.current + dir % len + len) % len
		},
		navigateTo(url) {
			const publicPath = "/" + this.$store.getters.getPublicPath;
			const locale = this.$store.getters.getLocale;
			if (url != "#") {
				this.$router.push(url);
				this.burger = false;
			}
		},
	},
	created() {
		setInterval(() => {
				this.transitionName = "slide-prev"
				var len = this.data.singlecell.length
				this.current = (this.current + 1 % len + len) % len
			}, 4500),
			this.show = true
	}
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.slider {
    display: flex;
    background-color: #efefef;
    justify-content: center;
}
.fade-enter-active {
    transition: opacity 1s;
}
.fade-enter {
    opacity: 0;
}

.slide-next-enter-active,
.slide-next-leave-active {
    transition: transform 1s ease-in-out, opacity 1.6s ease-in-out;
}
.slide-next-enter {
    transform: translate(30%);
    opacity: 0;
}
.slide-next-leave-to {
    transform: translate(-100%);
    opacity: 0;
}

/* GO TO PREVIOUS SLIDE */
.slide-prev-enter-active,
.slide-prev-leave-active {
    transition: transform 1s ease-in-out, opacity 0.4s ease-in-out;
}
.slide-prev-enter {
    transform: translate(-100%);
    opacity: 1;
}
.slide-prev-leave-to {
    transform: translate(100%);
    opacity: 0;
}

.main-slider {
    width: 80%;
    height: 500px;
    background-color: $beige;
    position: relative;
    overflow: hidden;
    background-image: url("../../assets/QUOTE_WHITE.svg");
    background-repeat: no-repeat;
    background-size: 275px;
    background-position: top center;
    @include touch {
        width: 100%;
    }
    &__slide {
        width: 100%;
        height: 500px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 900;
        img {
            width: auto;
            height: 45px;
            margin-top: 20px;
        }
    }
    &__buttons {
        position: absolute;
        //left: 10%;
        bottom: 0;
        z-index: 900;
        @include touch {
            bottom: 0;
        }
        a {
            display: inline-block;
            padding: 13px 10px 10px;
            background-color: #3273dc;
            margin-right: 1px;
            img {
                width: 40px;
            }
            &:nth-child(2) {
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &__columns {
        height: 500px;
        justify-content: center;
    }
    &__content {
        color: #3273dc !important;
        font-weight: 500;
        z-index: 800;
        text-align: center;
        max-width: 1200px;
        .is-size-4 {
            @include mobile {
                font-size: 1.25rem !important;
            }
        }
        span {
            margin-top: 20px;
            font-weight: 300;
            display: block;
            position: relative;
            margin-left: 30px;

        }
        @include touch {
            margin-right: 40px;
        }
    }
    &__column {
        position: relative;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 150px;
        @include hugemax {
            margin: 0 20px;
        }
        @include touch {
            width: 100% !important;
            max-width: 800px;
        }
        @include touch {
            padding-top: 40px;
        }
        @include mobile {
            padding-top: 80px;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: 800;
        //background-color: #efefef;
        background-image: url("https://szczepaniak.ch/cms/app/uploads/2019/07/LOGO-BG-1.svg");
        background-size: cover;
        @include touch {
            display: none;
        }
    }
    &__video {
        position: absolute;
        top: 0;
        right: 0;
        @include touch {
            display: none;
        }
    }
    &__logo {
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-image: url("https://szczepaniak.ch/cms/app/uploads/2019/07/W_OUTLINE.svg");
        background-size: 35%;
        background-repeat: no-repeat;
        background-position: 20% 50%;
    }

    // Modification for 2 version
    &--version-2 {
        .main-slider__content {
            color: $dark-blue;
            font-weight: 500;
            p,
            span {
                color: $dark-blue;
                opacity: 0.88;
            }
        }
        .main-slider__image {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
            @include touch {
                display: none;
            }
        }
    }
}
</style>
