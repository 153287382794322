<template>
    <div
        class="cookies"
        v-if="display"
    >
        <div class="cookies__container">
            <a
                href="#"
                @click.prevent="closeCookies"
            >
                <strong v-html="langTranslation.accept"></strong>
                <span v-html="langTranslation.info"></span>
            </a>
        </div>
    </div>
</template>

<script>
// Remember to type `npm i vue-cookies`
// and put it into main.js `import VueCookies from 'vue-cookies', Vue.use(VueCookies)`
// in app.vue import Cookies from '@/components/Cookies.vue'

// Translations
import json from './cookies.json'

export default {
    data() {
        return {
            display: true,
            expireDays: 30,
            lang: json
        }
    },
    created() {
        if (this.$cookies.get('cookiesAgree') == 'set') {
            this.display = false
        }
    },
    methods: {
        closeCookies(){
            if (this.$cookies.get('cookiesAgree') != 'set') {
                this.$cookies.set('cookiesAgree', 'set', 60 * 60 * 24 * this.expireDays)
                this.display = false
            }
        }
    },
    computed: {
        langTranslation(){
            switch(this.$store.getters.getLocale) {
                case "pl":
                    return {accept: this.lang.pl.accept, info: this.lang.pl.info};
                    break;
                case "en":
                    return {accept: this.lang.en.accept, info: this.lang.en.info};
                    break;
                default:
                    return {accept: this.lang.en.accept, info: this.lang.en.info};
            }
        }
    }
}
</script>

<style scoped lang="scss">
.cookies{
    width: 100%;
    background-color: black;
    position: fixed;
    bottom: 0;
    color: #fff;
    padding: 10px;
    z-index: 999;
    &__container{
        max-width: 1250px;
        width: 100%;
        margin: 0 auto;
    }
    a{
        color: #fff;
        font-size: .8rem;
    }
    span{
        font-size: .8rem;
    }
}
</style>
