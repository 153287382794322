<template>
<div class="slider">

	<div class="section-name">
		<div class="section-name__content">
			<span>Media</span>
		</div>
	</div>

	<section class="home-quote"
	        :class="[data.custom_class]"
	        :data-aos="data.animation"
	        data-aos-duration="1500">

		<div class="home-quote__container">
			<transition-group tag="div"
			    :name="transitionName"
			    class="slides-group">
				<div v-if="show"
				    :key="current"
				    class="home-quote__slide"
				    :class="data.singlecell[current].title">
					<div class="container">
						<div class="columns home-quote__columns">
							<div class="column home-quote__column">
								<div class="home-quote__content">
									<div class="slider-box slider-box--is-33">
										<img :src="data.singlecell[current].logo.url"
										    :alt="data.singlecell[current].logo.alt" />
									</div>
									<div class="slider-box slider-box--is-66">
										<p class="is-size-4">{{ data.singlecell[current].content }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition-group>
		</div>

		<div class="home-quote__more-container">
			<router-link class="read-more"
			    :to="data.link">{{ data.text_more }}</router-link>
		</div>

		<div class="home-quote__buttons">
			<a @click.prevent="slide(1)">
				<img src="https://szczepaniak.ch/cms/app/uploads/2019/07/ARROW.svg" />
			</a>
			<a @click.prevent="slide(-1)">
				<img src="https://szczepaniak.ch/cms/app/uploads/2019/07/ARROW.svg" />
			</a>
		</div>
	</section>
</div>
</template>

<!-- data.singlecell -->

<script>
export default {
	data() {
		return {
			current: 0,
			show: false,
			direction: 1,
			transitionName: "fade"
		};
	},
	methods: {
		slide(dir) {
			this.direction = dir;
			dir === 1 ?
				(this.transitionName = "slide-next") :
				(this.transitionName = "slide-prev");
			var len = this.data.singlecell.length;
			this.current = (this.current + (dir % len) + len) % len;
		},
		navigateTo() {
			/*const publicPath = "/" + this.$store.getters.getPublicPath;
			const locale = this.$store.getters.getLocale;
			if (url != "#") {
			  this.$router.push(url);
			  this.burger = false;
			}*/
		}
	},
	created() {
		setInterval(() => {
				this.transitionName = "slide-prev"
				var len = this.data.singlecell.length
				this.current = (this.current - 1 % len + len) % len
			}, 5000),
			this.show = true;
	}
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.slider {
    display: flex;
    background-color: #3273dc;
    justify-content: center;
    height: 475px;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section-name {
    width: 80%;
    margin: 0 auto;
    padding: 50px 30px 10px;
    display: flex;
    justify-content: flex-end;
    @include mobile {
        padding: 20px;
        width: 100%;
        text-align: left;
    }
    &__content {
        width: 66.6666%;
        text-align: left;
        padding-left: 34px;
        @include hugemaxmax {
            padding-left: 50px;
        }
        @include touch {
            padding-left: 10px;
        }
        @include mobile {
            padding-left: 10px;
            text-align: left;
            width: 100%;
        }
    }
    span {
        color: $white;
        text-transform: uppercase;
    }
}
.fade-enter-active {
    transition: opacity 1s;
}
.fade-enter {
    opacity: 0;
}

.slide-next-enter-active,
.slide-next-leave-active {
    transition: transform 1s ease-in-out, opacity 1.6s ease-in-out;
}
.slide-next-enter {
    transform: translate(30%);
    opacity: 0;
}
.slide-next-leave-to {
    transform: translate(-100%);
    opacity: 0;
}

/* GO TO PREVIOUS SLIDE */
.slide-prev-enter-active,
.slide-prev-leave-active {
    transition: transform 1s ease-in-out, opacity 0.4s ease-in-out;
}
.slide-prev-enter {
    transform: translate(-100%);
    opacity: 1;
}
.slide-prev-leave-to {
    transform: translate(100%);
    opacity: 0;
}

.home-quote {
    width: 80%;
    height: 400px;
    background-color: #3273dc;
    position: relative;
    overflow: hidden;
    //bottom: 0;
    //position: absolute;
    @include touch {
        width: 100%;
    }
    &__section-name {
        position: absolute;

        span {
            color: $white;
            text-transform: uppercase;
            z-index: 900;
        }
    }
    &__more-container {
        position: absolute;
        bottom: 75px;
        text-align: center;
        justify-content: center;
        display: flex;
        width: 100%;
        padding-right: 210px;
        z-index: 900;
        @include big-xl {
            padding-right: 140px;
        }
        @include big {
            padding-right: 110px;
        }
        @include column-break {
            padding-right: 60px;
        }
        @include mobile-m {
            padding-right: 0;
            right: -30px;
            bottom: 24px;
        }
        @include mobile-xs {
            right: -40px;
        }
        .read-more {
            position: relative;
            color: white;
            right: -30px;
            text-transform: uppercase;
            font-size: 18px;
            &::before {
                content: "";
                width: 20px;
                height: 1px;
                background-color: $white;
                display: block;
                position: absolute;
                left: -30px;
                top: 11px;
            }
        }
    }
    &__slide {
        width: 100%;
        height: 500px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 900;
        @include mobile {
            height: 700px;
        }
        @include mobile-m {
            height: 800px;
        }
        @include mobile-xs {
            height: 900px;
        }
        img {
            width: auto;
        }
    }
    &__buttons {
        position: absolute;
        //left: 10%;
        bottom: 0;
        z-index: 900;
        @include touch {
            bottom: 0;
        }
        a {
            display: inline-block;
            padding: 13px 10px 10px;
            background-color: #262647;
            margin-right: 1px;
            img {
                width: 40px;
            }
            &:nth-child(2) {
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &__columns {
        height: 500px;
        justify-content: center;
        @include mobile {
            height: 700px;
        }
        @include mobile-m {
            height: 800px;
        }
        @include mobile-xs {
            height: 900px;
        }
    }
    &__content {
        color: #fff !important;
        font-weight: 500;
        z-index: 800;
        text-align: center;
        max-width: 1200px;
        display: flex;
        @include mobile {
            flex-direction: column;
        }
        .slider-box {
            display: flex;
            padding: 20px 40px 40px;
            box-sizing: border-box;
            background-color: #3273dc;
            @include mobile {
                padding: 20px;
            }
            &--is-33 {
                width: 33.333%;
                @include mobile {
                    width: 100%;
                    padding: 10px;
                    justify-content: center;
                }
                @include mobile-xs {
                    padding-top: 0 !important;
                }
                img {
                    width: 100%;
                    @include mobile {
                        width: auto;
                        height: 50px;
                    }
                    @include mobile-xs {
                        height: 40px;
                    }
                    @include mobile-xs {
                        padding-top: 0 !important;
                    }
                }
            }
            &--is-66 {
                width: 66.666%;
                text-align: left;
                @include mobile {
                    width: 100%;
                }
                @include mobile-xs {
                    padding-top: 0 !important;
                }
                .is-size-4 {
                    font-size: 1.5rem !important;
                    @include touch {
                        font-size: 1.25rem !important;
                    }
                }
            }
        }
        span {
            margin-top: 20px;
            font-weight: 300;
            display: block;
            position: relative;
            margin-left: 30px;
        }
        @include touch {
            margin-right: 40px;
        }
    }
    &__column {
        position: relative;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 40px 0 80px;
        @include hugemax {
            margin: 0 20px;
        }
        @include big {
            padding-top: 30px;
        }
        @include column-break {
            padding-top: 0;
        }
        @include touch {
            width: 100% !important;
            max-width: 800px;
            padding-top: 40px;
        }
        @include mobile {
            padding-top: 20px;
        }
        @include mobile-xs {
            padding-top: 10px !important;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: 800;
        //background-color: #efefef;
        background-image: url("https://szczepaniak.ch/cms/app/uploads/2019/07/LOGO-BG-1.svg");
        background-size: cover;
        @include touch {
            display: none;
        }
    }
    &__video {
        position: absolute;
        top: 0;
        right: 0;
        @include touch {
            display: none;
        }
    }
    &__logo {
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-image: url("https://szczepaniak.ch/cms/app/uploads/2019/07/W_OUTLINE.svg");
        background-size: 35%;
        background-repeat: no-repeat;
        background-position: 20% 50%;
    }

    // Modification for 2 version
    &--version-2 {
        .home-quote__content {
            color: $dark-blue;
            font-weight: 500;
            p,
            span {
                color: $dark-blue;
                opacity: 0.88;
            }
        }
        .home-quote__image {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
            @include touch {
                display: none;
            }
        }
    }
}
</style>
