<template>
    <section
        class="intro section"
        :class="[data.custom_class]"
        :data-aos="data.animation"
        data-aos-duration="1500"
    >
        <div class="container">
            <div class="intro__content columns is-vcentered">
                <div
                    class="column is-marginless is-paddingless image-wrapper"
                    v-if="data.image.image"
                    :class="printClass(data,'image')"
                    :style="{order:data.image.responsive.fullhd_grid.order}"
                >
                    <div class="intro__image-box content">
                        <figure class="is-marginless is-paddingless image">
                            <img class="is-marginless is-paddingless lozad" v-lazy="data.image.image" :alt="data.image.alt">
                        </figure>
                    </div>
                </div>
                <div
                    class="column content-wrapper"
                    v-if="data.content.content"
                    :class="printClass(data,'content')"
                    v-lazy:background-image="data.quotation_mark"
                    :style="{order:data.content.responsive.fullhd_grid.order}"
                >
                    <h2 class="is-size-4" v-html="data.content.content"></h2>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "IntroSection",
    data() {
        return {}
    }
}
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"

.intro
    background-color: $beige
    padding: 0
    &__content
        +touch
            flex-direction: column
    &__image-box
        +touch
            padding-top: 2rem
    &__image-box
        padding-top: 30px
    .content-wrapper
        padding: 2rem 1rem
        background-repeat: no-repeat
        background-position: center top
        background-size: 180px
        +touch
            background-color: $dark-blue
            background-size: 50px
            background-position: 40px center
    /deep/ .content-wrapper h2
        color: $dark-blue
        font-weight: 500
        line-height: 1.35
        +touch
            padding: 0rem 1.5rem
            font-size: 1.25rem !important
            color: #fff
            opacity: .9
            padding-left: 100px
    /deep/ .content img
        display: block
        +touch
            max-width: 350px
        +mobile
            max-width: 300px
            margin: 0 auto
    /deep/ .image
        +touch
            display: flex
            justify-content: center
    &--sticky-left
        .image-wrapper
            transform: translateX(-250px)


</style>
